import React, { useEffect, useState } from "react";
import Logo from "../../assets/logo.png";
import { NavLink, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const [isHome, setIsHome] = useState(true);

  useEffect(() => {
    const path = location.pathname;
    if (
      path.includes("terms-and-conditions") ||
      path.includes("privacy-policy")
    ) {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [location.pathname]);

  const setSignInAndSignUpButtonStateToLocalStorage = (type) => {
    if (type === "signup") {
      localStorage.setItem("buttonState", "signup");
    } else if (type === "signin") {
      localStorage.setItem("buttonState", "signin");
    }
  };

  return (
    <header id="main-header" className={`${isHome ? "" : "bg-black"}`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <nav className="navbar navbar-expand-lg navbar-light">
              <a className="navbar-brand" href="/">
                <img className="img-fluid" src={Logo} alt="logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ion-navicon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto w-100 justify-content-end">
                  {isHome && (
                    <>
                      <li className="nav-item dropdown">
                        <a className="nav-link active" href="#iq-home">
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#how-it-works">
                          How It Works
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#software-features">
                          Why Us
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#pricing">
                          Pricing
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#contact">
                          Contact
                        </a>
                      </li>
                    </>
                  )}

                  <div className="gap-0 flex justify-center res-header">
                    <NavLink
                      to="/sign-in"
                      className="button2"
                      onClick={() =>
                        setSignInAndSignUpButtonStateToLocalStorage("signup")
                      }
                    >
                      Get Started
                    </NavLink>

                    <NavLink
                      to={"/sign-in"}
                      className="button bt-black text-center w-[153px]"
                      onClick={() =>
                        setSignInAndSignUpButtonStateToLocalStorage("signin")
                      }
                    >
                      Sign In
                    </NavLink>
                  </div>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

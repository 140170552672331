import React, { useEffect, useState } from "react";
import { Select, Input, Space, Button, message } from "antd";
import "antd/dist/reset.css";

const { Option } = Select;

const defaultCountries = [
  { code: "+1", name: "United States", flag: "🇺🇸", key: "USA" },
  { code: "+1", name: "Canada", flag: "🇨🇦", key: "Canada" },
  { code: "+61", name: "Australia", flag: "🇦🇺", key: "Australia" },
  { code: "+44", name: "UK", flag: "🇬🇧", key: "UK" },
  { code: "+880", name: "Bangladesh", flag: "🇧🇩", key: "Bangladesh" },
];

const PhoneNumberInput = ({ onSubmit }) => {
  const [countries, setCountries] = useState(defaultCountries);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountries[0].name); // Default to USA
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUserCountryByTimeZone = () => {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Match time zones to default country keys
      const timeZoneMapping = {
        "Asia/Dhaka": "Bangladesh",
        "America/New_York": "USA",
        "Europe/London": "UK",
        "America/Toronto": "Canada",
        "Australia/Sydney": "Australia",
      };

      // Fallback to United States if the time zone is not mapped
      const detectedCountry = timeZoneMapping[timeZone] || "United States";

      // Check if the detected country exists in defaultCountries
      const countryExists = defaultCountries.some((country) => country.name === detectedCountry);

      // Set the detected country if it exists, otherwise fallback to default (USA)
      setSelectedCountry(countryExists ? detectedCountry : "United States");
    };

    getUserCountryByTimeZone();
  }, []);


  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        const formattedCountries = data
          .map((country) => {
            let code = country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : "");
            if (country.name.common === "United States") {
              // console.log(country.car.signs[0])
              code = country.idd.root;
            }

            return {
              name: country.name.common,
              code: code,
              flag: country.flag,
            };
          })
          .filter((country) => country.code);

        setCountries(formattedCountries);
      } catch (error) {
        message.error("Failed to fetch country data.");
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePhoneNumberSubmit = () => {
    if (!phoneNumber) {
      message.error("Phone number must be provided.");
      return;
    }

    if (!/^\d+$/.test(phoneNumber)) {
      message.error("Phone number must contain only digits.");
      return;
    }

    const country = countries.find((country) => country.name === selectedCountry);
    const fullPhoneNumber = `${country.code}${phoneNumber}`;
    if (onSubmit) {
      onSubmit(fullPhoneNumber);
    }
  };

  return (
    <Space direction={isMobile ? "vertical" : "horizontal"} size="large" style={{ width: "100%" }}>
      <Space direction={isMobile ? "vertical" : "horizontal"}>
        <Select
          style={{ width: 180 }}
          value={selectedCountry}
          onChange={handleCountryChange}
          placeholder="Select Country"
          showSearch
          optionFilterProp="children"
          notFoundContent={loading ? "Loading, please wait..." : "No results found"}
        >
          {/* Default countries */}
          {defaultCountries.map((country) => (
            <Option key={`default-${country.name}`} value={country.name}>
              {country.flag} {country.code} ({country.name})
            </Option>
          ))}
          {/* Loaded countries (exclude duplicates) */}
          {!loading && countries
            .filter(
              (country) => !defaultCountries.some((defaultCountry) => defaultCountry.name === country.name)
            )
            .map((country) => (
              <Option key={`loaded-${country.name}`} value={country.name}>
                {country.flag} {country.code} ({country.name})
              </Option>
            ))}
          {loading && <Option disabled key="loading">Loading new country data...</Option>}
        </Select>

        <Input
          style={{ width: 250 }}
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={handlePhoneChange}
        />
      </Space>
      <Button type="primary" onClick={handlePhoneNumberSubmit}>
        Add
      </Button>
    </Space>
  );
};

export default PhoneNumberInput;

import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router";
import { useAuth } from "../../contexts/Auth";
import { message } from "antd";
import SignUpForm from "../../components/SignInAndSignUp/SignUpForm";
import SignInForm from "../../components/SignInAndSignUp/SignInForm";

function AuthPage() {
  const buttonState = localStorage.getItem("buttonState");

  //Sign-in form state
  const [isSignUp, setIsSignUp] = useState(
    buttonState === "signup" ? true : false
  );

  const [messageApi, contextHolder] = message.useMessage();

  const { user } = useAuth();

  // Navigation
  const navigate = useNavigate();

  useEffect(() => {
    //If user is already logged in, redirect them directly to the dashboard page
    messageApi.loading("Please wait...", 0);
    if (user) navigate("/dashboard");
    messageApi.destroy();
  }, [navigate, user]);

  return (
    <div className="bg-[#1D232A]">
      {contextHolder}
      <div className={`${isSignUp ? "h-[104px]" : "h-[10px]"} bg-[#1D232A]"}`}>
        <button onClick={() => navigate("/")}>
          <img
            className="w-64 md:w-80 lg:w-64 p-5"
            src={logo}
            alt="company logo"
          ></img>
        </button>
      </div>
      <div
        className="h-svh flex justify-center items-center sm:px-6 lg:px-8"
        data-theme="dark"
      >
        <div
          className={`max-w-xl md:max-w-2xl lg:max-w-4xl h-[${
            isSignUp === true ? 600 : 400
          }px] w-full md:w-3/4 lg:w-1/2 xl:w-1/3 space-y-8 p-10 bg-white bg-opacity-20 backdrop-blur-lg rounded-lg shadow-xl mt-6 mx-auto`}
        >
          <h2 className="text-center text-3xl md:text-4xl lg:text-5xl font-extrabold text-black">
            {isSignUp ? "Sign Up" : "Sign In"}
          </h2>
          <div className="flex justify-center my-4 mt-5 pt-3 gap-2">
            <button
              className={`w-1/2 py-2 text-sm md:text-md lg:text-lg font-medium rounded-l-lg ${
                isSignUp
                  ? "bg-white bg-opacity-10 text-black border-black"
                  : "bg-indigo-500 text-gray-100"
              }`}
              onClick={() => setIsSignUp(false)}
            >
              Sign In
            </button>
            <button
              className={`w-1/2 py-2 text-sm md:text-md lg:text-lg font-medium rounded-r-lg ${
                isSignUp
                  ? "bg-indigo-500 text-gray-100"
                  : "bg-white bg-opacity-10 text-black"
              }`}
              onClick={() => setIsSignUp(true)}
            >
              Sign Up
            </button>
          </div>

          <div className="">
            {/* sign in */}
            {!isSignUp && <SignInForm isSignUp={isSignUp} />}

            {/* sign up */}
            {isSignUp && (
              <SignUpForm isSignUp={isSignUp} setIsSignUp={setIsSignUp} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthPage;

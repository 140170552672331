import React, { useContext, useState, useEffect } from "react";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import { createClient } from "../utils/supabase/client";

//global state with supabase instance and user variables
const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [userProfile, setUserProfile] = useState();
  const [loading, setLoading] = useState(false);

  const supabase = createClient();

  useEffect(() => {
    async function checkUserSession() {
      setLoading(true);

      try {
        const session = await supabase.auth.getSession();

        if (session.data.session) {
          const user = session.data.session.user;
          setUser(user);
        } else {
          setUser(null);
          setUserProfile(null);
        }
      } catch (error) {
        // console.error("Error fetching session or profile:", error);
      } finally {
        setLoading(false);
      }
    }

    checkUserSession();

    const {
      data: { listener },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        checkUserSession(); // Re-use the same function to handle session changes
      } else {
        setLoading(false); // Ensure loading is set to false if session is null
      }
    });

    return () => listener?.unsubscribe();
  }, [supabase]);

  const state = {
    user,
    loading,
    userProfile,
    
    // logout: () => setUser(null),
    logout: async () => {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error('Error during sign out:', error.message);
      } else {

        const session = await supabase.auth.getSession();
        if (!session.data.session) {
          console.log('Successfully signed out, session cleared.');
          localStorage.removeItem("supabase.auth.token");
          localStorage.removeItem("supabase.auth.expires_at");
          setUser(null);  // Ensure user state is cleared
          // navigate("/", { replace: true });  // Ensure navigation after session is cleared
          // window.location.href = window.location.origin;
        } else {
          console.error('Session not cleared.');
          // setUser(null);
        }

      }
    }
  };

  return (
    <AuthContext.Provider value={state}>
      {loading ? <LoadingIndicator /> : children}
    </AuthContext.Provider>
  );
}

//children can use this function to access context properties
export function useAuth() {
  return useContext(AuthContext);
}

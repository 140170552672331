import Banner from "../../assets/banner/01.png";
import Drive from "../../assets/drive/07.png";
import Chrome from "../../assets/services/icon1.png";
import Plan from "../../assets/services/icon2.png";
import Rocket from "../../assets/services/icon3.png";
import Logo from "../../assets/logo.png";
import "./css/bootstrap.min.css";
import "./css/font-awesome.css";
import "./css/ionicons.min.css";
import "./css/variables.css";
import "./css/style.css";
import "./css/color.css";
import "./css/responsive.css";
import "./css/custom.css";
import { NavLink } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import React, { useEffect, useState } from "react";
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, TikTokOutlined, YoutubeOutlined } from '@ant-design/icons';

const socialIcons = {
  facebook_url: <FacebookOutlined />,
  instagram_url: <InstagramOutlined />,
  linkdin_url: <LinkedinOutlined />,
  tiktok_url: <TikTokOutlined />, // Add TikTokOutlined if available
  youtube_url: <YoutubeOutlined />,
};

export default function LandingPage() {
  const [socialLinks, setSocialLinks] = useState([]);
  const [youtube_url, setYoutubeUrl] = useState('');
  const setSignInAndSignUpButtonStateToLocalStorage = (type) => {
    if (type === "signup") {
      localStorage.setItem("buttonState", "signup");
    } else if (type === "signin") {
      localStorage.setItem("buttonState", "signin");
    }
  };



  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await fetch(`/api/social/settings`);
        const data = await response.json();
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const apiLinks = data?.data[0]; // Assuming this contains your social link data

        if (apiLinks) {
          setYoutubeUrl(apiLinks.youtube_video_link)
          const formattedLinks = Object.entries(apiLinks)
            .filter(([key, url]) => socialIcons[key] && url) // Filter known keys and valid URLs
            .map(([key, url]) => ({
              id: key,
              icon: socialIcons[key],
              url,
              name: key.replace('_url', '').replace('linkdin', 'LinkedIn').toUpperCase(),
            }));



          setSocialLinks(formattedLinks); // Store links in state
        } else {
          console.error('No social links found in API.');
        }
      } catch (error) {
        console.error('Error fetching social links:', error);
      }
    };

    fetchSocialLinks();
  }, []);

  return (
    <>
      <Header />

      <section
        id="iq-home"
        className="iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax"
        data-jarallax='{"speed": 0.6}'
        style={{ background: "url(images/bg/01.jpg)" }}
      >
        <div className="container-fluid">
          <div className="banner-text">
            <div className="row justify-content-between">
              <div className="col-lg-6 align-self-center">

                <h1 className="text-uppercase iq-font-white iq-tw-3 text-[24px]!">
                  ACCELERATE YOUR JOB SEARCH AND CLIENT ACQUISITION WITH
                  SWIFTLEAD
                  <span className="italic">
                    {" "}
                    —ALL IN ONE POWERFUL PLATFORM.
                  </span>
                </h1>

                <p className="iq-font-white iq-pt-18 iq-mb-40">
                  Whether you’re a professional seeking new job opportunities or
                  a business looking to expand, swiftlead will find you the
                  right people to connect with and help you drive results!
                </p>

                <NavLink
                  to={"/sign-in"}
                  className="button3 bt-black iq-mt-5 iq-ml-0 "
                  onClick={() =>
                    setSignInAndSignUpButtonStateToLocalStorage("signup")
                  }
                >
                  Start Free Trial
                </NavLink>
              </div>
              <div className="col-lg-6">
                <img className="banner-img" alt="banner" src={Banner} />
              </div>
            </div>
          </div>
        </div>
        <div className="banner-objects">
          <span
            className="banner-objects-01"
            data-bottom="transform:translatey(50px)"
            data-top="transform:translatey(-50px);"
          >
            <img src={Drive} alt="drive02" />
          </span>
          <span className="banner-objects-02 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
          <span className="banner-objects-03 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
        </div>
      </section>

      <div className="main-content">
        <section
          id="how-it-works"
          className="overview-block-ptb it-works bg-white"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading-title">
                  <h3 className="title iq-tw-7">3 Simple Steps To Launch </h3>
                  <p>
                    Getting started with swiftlead is a simple, fast process,
                    unlocking unmatched
                    <br />
                    productivity and efficiency has never been easier.
                  </p>
                  <div>
                    <button onClick={() => window.open(youtube_url, '_blank')}
                      className="button button-gradient">How to Start Free Trial</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-4">
                <div className="iq-works-box text-left">
                  <div className="step">1</div>
                  <div className="icon-bg text-center">
                    <img src={Chrome} className="img-fluid" alt="Chrome" />
                  </div>
                  <h5 className="iq-tw-7 text-uppercase iq-mt-25 iq-mb-15">
                    Chrome Extension
                  </h5>
                  <p>Download our chrome extension.</p>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 r-mt-30">
                <div className="iq-works-box text-left">
                  <div className="step">2</div>
                  <div className="icon-bg text-center">
                    <img src={Plan} className="img-fluid" alt="Plan" />
                  </div>
                  <h5 className="iq-tw-7 text-uppercase iq-mt-25 iq-mb-15">
                    Plan
                  </h5>
                  <p>Sign up for our plan.</p>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 r-mt-30">
                <div className="iq-works-box text-left">
                  <div className="step">3</div>
                  <div className="icon-bg text-center">
                    <img src={Rocket} className="img-fluid" alt="Rocket" />
                  </div>
                  <h5 className="iq-tw-7 text-uppercase iq-mt-25 iq-mb-15">
                    Enjoy
                  </h5>
                  <p>Let swiftlead work its magic.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="software-features"
          className="overview-block-ptb iq-over iq-parallax"
          data-jarallax='{"speed": 0.6}'
          style={{
            background: "url(images/bg/05.jpg)",
            backgroundColor: "#1A1F63",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading-title white">
                  <h3 className="title iq-tw-7">Why You Should Choose Us</h3>
                </div>
              </div>

              <div className="row ">
                <div className="col-sm-4">
                  <div className="flex gap-2 iq-services-box text-left iq-font-white">
                    <div className="text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-14"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                        />
                      </svg>
                    </div>
                    <div className="services-content">
                      <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                        Targeted Insights at Your Fingertips
                      </h5>
                      <p>
                        swiftlead delivers essential data directly from job
                        postings, giving you quick access to decision-makers'
                        profiles, company details, and job specifics. Whether
                        you’re pursuing a new job or new clients, you'll have
                        everything you need to connect effectively.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="flex gap-2 iq-services-box text-left iq-font-white">
                    <div className="iq-icon text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-14"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                        />
                      </svg>
                    </div>
                    <div className="services-content">
                      <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                        Seamless Browser-Based Integration
                      </h5>
                      <p>
                        Our tool operates right in your browser, discreetly
                        gathering data without detection or complicated setups.
                        Enjoy a seamless, automated experience that saves time
                        while delivering valuable insights to support your
                        outreach.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="flex gap-2 iq-services-box text-left iq-font-white">
                    <div className="iq-icon text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-14"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
                        />
                      </svg>
                    </div>
                    <div className="services-content">
                      <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                        Amplify Your Outreach Strategy
                      </h5>
                      <p>
                        swiftlead streamlines prospecting by removing the
                        tedious steps, letting you focus on personalizing
                        communication for each opportunity. Build meaningful
                        connections with potential employers or clients,
                        effortlessly scaling your reach and impact.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* trail */}
        <section
          id="pricing"
          className="overview-block-ptb grey-bg iq-price-table"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading-title">
                  <h3 className="title iq-tw-7">Affordable Price</h3>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center md:justify-center md:gap-10 md:flex md:flex-row">
              {/* trail  */}
              <div className="w-[358px] mb-10">
                <div className="iq-pricing text-center">
                  <div
                    className="price-title iq-parallax"
                    style={{ backgroundColor: "#5271FF" }}
                    data-jarallax='{"speed": 0.6}'
                  >
                    <h2 className="iq-font-white iq-tw-7">
                      <small>$</small>0
                    </h2>
                    <span className="text-uppercase iq-tw-4 iq-font-white">
                      7 Day Free Trail
                    </span>
                  </div>
                  <ul>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      35 Searches For 7 Days
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Gold-Standard Data Collection
                    </li>

                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Anti-Detection Features
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Browser-Based Automation
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Lightweight and Reliable
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Secure and Private
                    </li>
                  </ul>
                  <div className="col-md-4 col-lg-4 wow flipInY r4-mt-30"></div>
                  <div className="price-footer">
                    <NavLink
                      className="button"
                      to={"/sign-in"}
                      onClick={() =>
                        setSignInAndSignUpButtonStateToLocalStorage("signup")
                      }
                    >
                      Start Free Trial
                    </NavLink>
                  </div>
                </div>
              </div>

              {/* main pack */}
              <div className="w-[358px]">
                <div className="iq-pricing text-center">
                  <div
                    className="price-title iq-parallax"
                    style={{ backgroundColor: "#5271FF" }}
                    data-jarallax='{"speed": 0.6}'
                  >
                    <h2 className="iq-font-white iq-tw-7">
                      <small>$</small>4.99<small>/Month</small>
                    </h2>
                    <span className="text-uppercase iq-tw-4 iq-font-white">
                      Premium Plan
                    </span>
                  </div>
                  <ul>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Unlimited Searches Per Month
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Gold-Standard Data Collection
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Anti-Detection Features
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Browser-Based Automation
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Lightweight and Reliable
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Secure and Private
                    </li>
                  </ul>
                  <div className="col-md-4 col-lg-4 wow flipInY r4-mt-30"></div>
                  <div className="price-footer">
                    <NavLink
                      className="button"
                      to={"/sign-in"}
                      onClick={() =>
                        setSignInAndSignUpButtonStateToLocalStorage("signup")
                      }
                    >
                      Get Started
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>






      <section className="contact-area" id="contact">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="contact-content text-center">
            <a href="/">
              <img src={Logo} alt="logo" />
            </a>

            <h1 style={{ fontSize: "30px", color: "#fff" }}>Support</h1>
            <a
              href="mailto:support@swiftlead.io"
              style={{ fontSize: "25px", color: "#fff" }}
            >
              support@swiftlead.io
            </a>
            <br />
            <p
              style={{
                margin: "0%",
                padding: "0%",
                color: "#fff",
                fontSize: "larger",
              }}
            >
              Reach out anytime.
            </p>

            <div className="flex justify-center space-x-4 mt-4">
              {socialLinks?.map(({ id, icon, url, name }) => (
                <a
                  key={id}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={name}
                  className="text-white hover:text-blue-500 transition-colors duration-300"
                >
                  <span className="text-2xl">{icon}</span>
                </a>
              ))}
            </div>
          </div>

        </div>
      </section>

      <Footer />
    </>
  );
}

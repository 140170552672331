import { createClient } from "../../utils/supabase/client";
import { useEffect, useRef, useState } from "react";
// import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import ActiveSubscription from "./ActiveSubscription";
import { useNavigate } from "react-router";
import Logo from "../../assets/logo.png";
import Pricing from "./Pricing";
import { Modal, Input, Button, Space, message } from "antd";
import { ExclamationCircleFilled, MobileOutlined } from "@ant-design/icons";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";
// import { RecaptchaVerifier } from "firebase/auth";
// import { auth, RecaptchaVerifier, signInWithPhoneNumber } fro../../firebase/firebase.configfig";
import firebase from "../../firebase/firebase"; // Updated import
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

const { confirm } = Modal;

export default function Subscriptions() {
  const supabase = createClient();
  const [subscription, setSubscription] = useState(null);
  const [user, setUser] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [userData, setUserData] = useState();
  const [isPhoneNumberExists, setIsPhoneNumberExists] = useState();
  const [isUserPhoneVerified, setIsUserPhoneVerified] = useState();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [modal, modalContextHolder] = Modal.useModal();
  // firebase
  const [verificationId, setVerificationId] = useState('');
  const recaptchaRef = useRef(null);
  const [isExit, setisExit] = useState(false);


  const formatPhoneNumber = (phoneNumber) => {
    return `+${phoneNumber}`;
  };

  const onPageLoad = async () => {
    setIsLoading(true);

    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (user) {
      setUser(user);
    }

    if (!user) {
      navigate("sign-in");
      return;
    }

    const { data: subscription, error } = await supabase
      .from("subscriptions")
      .select("*, prices(*, products(*))")
      .in("status", ["trial", "active"])
      .maybeSingle();
    if (error) {
      setIsLoading(false);
    }

    if (subscription) {
      setSubscription(subscription);
    }

    const { data: products } = await supabase
      .from("products")
      .select("*, prices(*)")
      .eq("active", true)
      .eq("prices.active", true)
      .order("metadata->index")
      .order("unit_amount", { referencedTable: "prices" });

    setProducts(products);
    setIsLoading(false);
  };

  useEffect(() => {
    onPageLoad();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userToken = (await supabase.auth.getSession()).data.session
          .access_token;

        const {
          data: { user },
          error,
        } = await supabase.auth.getUser(userToken);

        if (error) {
          console.error("Error fetching user:", error);
        } else {
          setUserData(user);
          setIsUserPhoneVerified(user?.user_metadata.phone_verified);
        }
      } catch (err) {
        console.error("Error during user data fetch:", err);
        // navigate('/sign-in', { replace: true })
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    setIsPhoneNumberExists(user?.phone === "" ? false : true);
  }, [user, isExit]);


  // useEffect(() => {
  //   const checkUserOTP = async () => {
  //     try {
  //       const userToken = (await supabase.auth.getSession()).data.session
  //         .access_token;

  //       // Check if the user's phone number is verified by hitting the API
  //       const response = await fetch("/api/twilio/isUserOTPExistsInDB", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`, // Include the token here
  //         },
  //         body: JSON.stringify({
  //           email: userData?.email,
  //         }),
  //       });

  //       const result = await response.json();

  //       if (!response.ok) {
  //         console.log(
  //           "Failed to check OTP existence:",
  //           result.error || "Unknown error"
  //         );
  //         return;
  //       }

  //       console.log("API Response:", result);
  //     } catch (error) {
  //       console.error("Error while checking user OTP:", error);
  //     }
  //   };

  //   if (!isUserPhoneVerified) {
  //     // checkUserOTP();
  //   }
  // }, [isUserPhoneVerified]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const sendOtp = async (number) => {
    // console.log(number, 'heheh send otp');
    messageApi.loading("Please wait we are sending the OTP...", 0);

    const formattedNumber = number.includes("+") ? number : formatPhoneNumber(userData?.phone);

    if (recaptchaRef.current) {
      recaptchaRef.current.innerHTML = ''
      recaptchaRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
    }

    const verifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        console.log('reCAPTCHA solved');
      },
      'expired-callback': () => {
        console.log('reCAPTCHA expired');
      }
    });

    try {
      const result = await firebase.auth().signInWithPhoneNumber(formattedNumber, verifier);
      setVerificationId(result.verificationId);  // Ensure this updates the state
      messageApi.destroy();
      Modal.destroyAll();
      message.success("OTP sent successfully!");
      // console.log('success', result);

      Modal.confirm({
        title: "Enter OTP",
        content: (
          <div style={{ marginTop: "1rem" }}>
            <Input.OTP placeholder="Enter OTP" length={6} onChange={(e) => onChange(e, result.verificationId)} />
          </div>
        ),
        okButtonProps: { style: { display: "none" } },
        cancelText: "Cancel",
      });
    } catch (error) {
      message.error("Failed to send OTP");
      console.error("Error sending OTP:", error);
      switch (error.code) {
        case "auth/too-many-requests":
          message.error("Too many requests. Please try again later.");
          break;
        case "auth/invalid-phone-number":
          message.error("The phone number is invalid.");
          break;
        default:
          message.error("Something went wrong. Please try again later.");
          break;
      }
    }
  };



  const onChange = (otp, verificationId) => {
    // console.log('otp entered:', otp);
    verifyOtp(otp, verificationId);
  };

  const verifyOtp = async (otp, verificationId) => {
    // console.log(verificationId)
    if (!verificationId) {
      console.error("Missing verificationId. Ensure sendOtp was called.");
      return;
    }
    try {
      messageApi.loading("Please wait we are verifying your OTP...", 0);
      const userToken = (await supabase.auth.getSession()).data.session
        .access_token;
      const formattedNumber = formatPhoneNumber(userData?.phone);


      const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
      const result = await firebase.auth().signInWithCredential(credential);
      // console.log('otp success', result.user);
      if (result.user) {

        fetch(`/api/firebaseotp/otpVerification`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({ phone_number: formattedNumber }),
        }).then(result => {

          if (result.ok) {
            messageApi.destroy();
            message.success("Phone number verified successfully!");
            setTimeout(() => {
              window.location.reload();
            }, 1500);

          }
        })

        messageApi.destroy();
        Modal.destroyAll();
      }
    } catch (error) {
      message.error("OTP verification failed");
      console.error('Error verifying OTP:', error);
    }
  };
  const sharedProps = {
    onChange,
  };

  const showConfirm = (phone_number) => {
    const number = phone_number ? phone_number : userData?.phone;
    confirm({
      title: "Verify phone to get more benefits",
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <Space.Compact style={{ width: "100%" }}>
            <Input value={number} disabled />
            <Button type="primary" onClick={() => sendOtp(number)}>
              Send OTP
            </Button>
          </Space.Compact>
        </div>
      ),
      okButtonProps: { style: { display: "none" } },
      cancelText: "Cancel",
      maskClosable: true,
      closable: true,
    });
  };

  const confirmAddNumber = () => {
    modal.confirm({
      title: "Please add a valid phone number",
      icon: <MobileOutlined />,
      content: (
        <div style={{ marginTop: "10px" }}>
          <PhoneNumberInput onSubmit={handlePhoneNumberSubmit} />
        </div>
      ),
      width: 600,
      footer: null,
      maskClosable: true,
      closable: true,
    });
  };

  const handlePhoneNumberSubmit = async (phoneNumber) => {
    try {
      messageApi.loading("Please wait we are adding your number...", 0);
      const userToken = (await supabase.auth.getSession()).data.session
        .access_token;

      const response = await fetch(`/api/dashboard/addPhoneNumber`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ phoneNumber }),
      });
      if (response.ok) {
        messageApi.destroy();
        message.success("Phone number added successfully!");
        Modal.destroyAll();
        showConfirm(phoneNumber);
      } else {
        const data = await response.json();
        messageApi.destroy();
        message.error(data?.error || "Phone number add operation failed!");
      }
    } catch (error) {
      messageApi.destroy();
      message.error(error.message || "Verification failed");
    }
  };

  if (subscription && user) {
    return (
      <div>
        {contextHolder}
        <ActiveSubscription
          subscription={subscription}
          user={user}
          products={products}
        />
      </div>
    );
  } else {
    return (
      <div className="flex flex-col min-h-screen" data-theme="dark">
        {contextHolder}
        <div className="hero flex-1">
          <div className="hero-content flex-col">
            <img src={Logo} alt="company logo" className="rounded-lg w-32" />
            <div>
              {isPhoneNumberExists ? (
                <div className="text-center">
                  {userData?.user_metadata?.phone_verified ? (
                    <div className="flex flex-col justify-center items-center font-extrabold gap-2 text-green-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                        />
                      </svg>
                      VERIFIED
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <p>Please verify your phone number</p>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  Please add your phone number to continue using all features of
                  your account.
                  {modalContextHolder}
                </>
              )}
            </div>

            <Pricing
              user={user}
              products={products ?? []}
              subscription={subscription}
              isPhoneVerified={userData?.user_metadata?.phone_verified}
              isNumberExists={userData?.phone}
              showConfirm={showConfirm}
              confirmAddNumber={confirmAddNumber}
            />
          </div>
        </div>
        <div ref={recaptchaRef}></div>
      </div>
    );
  }
}
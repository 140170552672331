import "./index.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import LandingPage from "./pages/LandingPage/LandingPage";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./contexts/Auth";
import Subscriptions from "./pages/Dashboard/Subscriptions";
import SignIn from "./pages/SignIn/SignIn";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import NotFoundPage from "./NotFoundpage"


//check




function App() {

  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/sign-in" element={<SignIn />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route exact path="/dashboard" element={<PrivateRoute />}>
            <Route exact path="/dashboard" element={<Subscriptions />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;

//vercel account:
//email: zach.hayward93@gmail.com
//password: Hannah11304!

//supabase account:
//email: zach.hayward93@gmail.com
//password: Hannah11304!
//db password: BigLinkLeads##
//

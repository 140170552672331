"use client";
import React, { useState, useEffect } from "react";
import { createClient } from "../../utils/supabase/client";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { useNavigate } from "react-router";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../../components/Snackbar/Snackbar";
import Logo from "../../assets/logo.png";
import BackgroundSVG from "../../assets/banner/blurry-gradient-haikei.svg";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import Tooltip from "./ToolTip";
import JobTable from "../../components/JobTable/JobTable";
import { getStripe } from "../../utils/stripe/client";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useAuth } from "../../contexts/Auth";
const { confirm } = Modal;

function ActiveSubscription({ subscription, user, products }) {
  const { logout } = useAuth();
  const supabase = createClient();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState(); // State for storing jobs
  const { showSnackbar, message, triggerSnackbar } = useSnackbar();

  let sub_status = "";

  if (subscription.status === "trial") {
    sub_status = "trial";
  } else if (subscription.status === "active") {
    sub_status = "Active";
  } else if (subscription.status === "inactive") {
    sub_status = "Inactive";
  } else {
    sub_status = "Unknown Status";
  }

  //Create new Stripe portal session to allow user to manage subscription
  const handleStripePortalRequest = async () => {
    setIsLoading(true);

    //get jwt token for the user that is logged in
    const userToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const resp = await fetch("/api/stripe/customerPortal", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const response = await resp.json();

    if (resp.ok) {
      const url = response.url;
      window.location.href = url; //navigate to customer portal
      return;
    } else {
      triggerSnackbar(response.error);
    }

    setIsLoading(false);
  };

  // handle stripe premium pack buy
  const handleStripeCheckout = async (price) => {
    setIsLoading(true);

    try {
      const userToken = (await supabase.auth.getSession()).data.session
        .access_token;
      const resp = await fetch("/api/stripe/buyPlan", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ price }),
      });

      const json = await resp.json();

      if (resp.ok) {
        const { sessionId } = json;
        const stripe = await getStripe();
        await stripe.redirectToCheckout({ sessionId });
      } else {
        triggerSnackbar(json.error);
        setIsLoading(false);
      }
    } catch (err) {
      console.error("Error in handleStripeCheckout:", err);
      triggerSnackbar("An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadEvent = () => {
    // Open the Chrome Web Store page in a new tab
    window.open(
      "https://chromewebstore.google.com/detail/swiftlead/jfmakahmfclplndeekkpolafgllnnnkk",
      "_blank"
    );
  };

  const formatSubscriptionPrice = (data) => {
    if (sub_status === "trial") {
      // Handle trial status formatting
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: data.prices[0].currency,
        minimumFractionDigits: 0,
      }).format((data?.prices[0]?.unit_amount || 0) / 100);
    } else if (sub_status === "Active") {
      // Handle active status formatting
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: data.prices.currency,
        minimumFractionDigits: 0,
      }).format((data?.prices?.unit_amount || 0) / 100);
    } else {
      // Return a default value or handle other statuses
      return "Invalid status";
    }
  };

  // const formattedDate = subscription?.current_period_end
  //   ? moment(subscription.current_period_end).format("MMMM D, YYYY")
  //   : "";

  const isExpired = subscription?.current_period_end
    ? new Date(subscription.current_period_end) < new Date()
    : false;

  const formattedDate = subscription?.current_period_end
    ? moment(subscription.current_period_end).format("MMMM D, YYYY")
    : "";

  const fetchJobs = async () => {
    try {
      setIsLoading(true);
      const userToken = (await supabase.auth.getSession()).data.session
        .access_token;

      const resp = await fetch("/api/dashboard/getAllJobsFromDB", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      const data = await resp.json();

      if (resp.ok) {
        setJobs(data);
      } else {
        console.error(data.error || "Failed to fetch jobs");
        triggerSnackbar(data.error || "Failed to fetch jobs");
      }
    } catch (error) {
      console.error(error.message || "An error occurred");
      triggerSnackbar(error.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  console.log(sub_status, "sub_status");
  // const afterTrailPack = products[0];
  const afterTrailPack = products.find((item) => item.name === "Premium Plan");
  console.log(afterTrailPack, "afterTrailPack");
  // Function to show the confirmation modal
  const showPromiseConfirm = () => {
    confirm({
      title: "Do you want to manage your subscription?",
      icon: <ExclamationCircleFilled />,
      content: "Click OK to proceed with the action.",
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              if (sub_status === "trial") {
                handleStripeCheckout(afterTrailPack.prices[0]);
              } else {
                handleStripePortalRequest();
              }
              resolve(); // Resolve the promise on success
            } catch (error) {
              console.error("Operation failed:", error);
              reject(); // Reject the promise on failure
            }
          }, 1000); // Simulate a delay for the operation
        }).catch(() => console.log("Oops! Something went wrong."));
      },
      onCancel() {
        console.log("Operation canceled.");
      },
      okButtonProps: {
        style: {
          backgroundColor: "#536cef",
          borderColor: "#536cef",
          color: "#fff",
        },
      },
    });
  };

  return (
    <div className="p-6 space-y-6 bg-white">
      <header id="main-header" className="bg-black">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 flex justify-center items-center">
              <div className="navbar">
                <Link to="/" className="navbar-brand">
                  <img className="img-fluid" src={Logo} alt="logo" />
                </Link>
              </div>
              <div className="w-28">
                <button
                  onClick={logout}
                  className="bg-red-500 hover:bg-red-600 focus:bg-red-600 active:bg-red-700 transition-colors duration-300 py-2 px-6 rounded-lg font-bold text-white focus:outline-none focus:ring-2 focus:ring-red-300 shadow-md"
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div>
        {/* account details */}
        <div className="mb-6 md:mb-0 pt-12 md:p-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            <div
              className="rounded-lg border bg-card text-card-foreground shadow-sm p-6"
              data-v0-t="card"
            >
              <div className="flex flex-col space-y-1.5 p-6 text-center">
                <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
                  Account
                </h3>
              </div>
              <div className="p-6 space-y-2">
                <div className="flex justify-between">
                  <span className="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-[28px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                    {user.email ? "Email" : "Phone"} :
                  </span>
                  <Tooltip params={user.email ? user.email : user.phone} />
                </div>
                <div className="flex justify-between">
                  <span className="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-[28px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                    Subscription :
                  </span>

                  <span
                    className={
                      subscription.status === "active" ||
                        subscription.status === "trial"
                        ? new Date(subscription.current_period_end) < new Date()
                          ? "text-red-500" // Expired period
                          : "text-green-500" // Valid period
                        : "text-gray-500" // Inactive or other status
                    }
                  >
                    {subscription.status === "active"
                      ? new Date(subscription.current_period_end) < new Date()
                        ? "Deactivated" // Expired active subscription
                        : "Active" // Valid active subscription
                      : subscription.status === "trial"
                        ? new Date(subscription.current_period_end) < new Date()
                          ? "Deactivated" // Expired trial subscription
                          : "Trial" // Valid trial subscription
                        : "Inactive"}
                    {/* Other statuses */}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-[28px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z"
                      />
                    </svg>
                    Plan :
                  </span>
                  <span>
                    {sub_status === "trial" ? "Free Trial" : "Premium"}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="rounded-lg border bg-card text-card-foreground shadow-sm p-6"
              data-v0-t="card"
            >
              <div className="flex flex-col space-y-1.5 p-6 text-center">
                <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
                  Searches
                </h3>
              </div>
              <div className="p-6 space-y-2">
                <div className="flex justify-between">
                  <span className="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-[28px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                    Total Searches Completed :
                  </span>
                  <span>{subscription?.total_reports_run}</span>
                </div>
                <div className="flex justify-between">
                  <span className="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-[28px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                    Total Searches Remaining :
                  </span>
                  <span>
                    {sub_status === "trial"
                      ? `${subscription.messages_left_period} / 35`
                      : "Unlimited"}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="rounded-lg border bg-card text-card-foreground shadow-sm p-6"
              data-v0-t="card"
            >
              <div className="flex flex-col space-y-1.5 p-6 text-center">
                <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
                  Subscription
                </h3>
              </div>
              <div className="p-6 space-y-2">
                <div className="flex justify-between">
                  <span className="flex items-center gap-1 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-[28px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                      />
                    </svg>
                    {subscription.status === "trial" ||
                      new Date(subscription.current_period_end) < new Date()
                      ? "Expiration Date :"
                      : "Renewal Date :"}
                  </span>

                  <span>
                    {isExpired
                      ? formattedDate // Show stagnant date if expired
                      : formattedDate}
                    {/* Show next renewal date if active */}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-[28px] "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                    Renewal Price :
                  </span>

                  {sub_status === "trial" ? (
                    <span className="text-black">
                      {afterTrailPack?.name} -
                      {`${formatSubscriptionPrice(afterTrailPack)}/${afterTrailPack?.prices[0]?.interval
                        }`}
                    </span>
                  ) : (
                    <span className="text-black">
                      {subscription?.prices?.products?.name} -
                      {`${formatSubscriptionPrice(subscription)}/${subscription?.prices?.interval
                        }`}
                    </span>
                  )}
                </div>
                <button
                  onClick={showPromiseConfirm}
                  className="inline-flex items-center justify-center hover:text-black whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-primary/90 h-10 px-4 py-2 w-full bg-[#5271ff] text-white"
                >
                  Manage Subscription
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Title Section */}
      <h2
        className="text-2xl font-bold text-center mb-6"
        style={{ fontFamily: "Arial, sans-serif" }}
      >
        Opportunity Data
      </h2>
      {/* table */}
      <JobTable jobData={jobs?.data} />

      {/* chrome download section */}

      <div
        className="w-[96.5%] mx-auto rounded-lg overflow-hidden bg-card text-card-foreground shadow-sm p-6"
        style={{
          backgroundImage: `url(${BackgroundSVG})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "0.5rem",
        }}
      >
        <div
          className="rounded-lg border bg-card text-card-foreground shadow-sm p-6"
          data-v0-t="card"
        >
          <div className="w-2/3 mx-auto p-6 space-y-2 flex flex-col items-center">
            <p className="text-white">
              Boost your productivity with our Chrome extension.
            </p>
            <button
              onClick={handleDownloadEvent}
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-primary/90 h-10 px-4 py-2 md:w-[30%] bg-[#5271ff] text-white gap-2"
            >
              Download Extension
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {showSnackbar && <Snackbar message={message} />}
    </div>
  );
}

export default ActiveSubscription;

import React from "react";
import { Form, Input, Row, Col, message } from "antd";
import { MailOutlined, LockOutlined, FormOutlined } from "@ant-design/icons";
import { createClient } from "../../utils/supabase/client";
import { Checkbox } from "antd";

const supabase = createClient();

const SignUpForm = ({ isSignUp, setIsSignUp }) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = async (values) => {
    const { email, password, confirmPassword, firstName, lastName } = values;

    // Check if passwords match
    if (password !== confirmPassword) {
      message.warning("Passwords do not match!");
      return;
    }

    messageApi.loading("Please wait, your data is registering...", 0);

    // Sign-up logic
    try {
      const { error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            first_name: firstName,
            last_name: lastName,
            fullName: `${firstName} ${lastName}`,
          },
        },
      });

      if (error) {
        messageApi.destroy();
        message.error(error.message);
        return;
      }

      messageApi.destroy();
      message.success("Account created successfully, Now logging in!");
      form.resetFields(); // Clear form
      setIsSignUp(false); // Switch to sign-in
    } catch (err) {
      messageApi.destroy();
      message.error("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <>
      {contextHolder}
      {isSignUp && (
        <Form
          form={form}
          name="signUp"
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            {/* First Name */}
            <Col xs={24} sm={12}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<FormOutlined />}
                  placeholder="First Name"
                />
              </Form.Item>
            </Col>

            {/* Last Name */}
            <Col xs={24} sm={12}>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<FormOutlined />}
                  placeholder="Last Name"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Email */}
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input a valid email!",
              },
            ]}
          >
            <Input size="large" prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>

          {/* Password */}
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                min: 6,
                message: "Password must be at least 6 characters long!",
              },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>

          {/* Confirm Password */}
          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match!"));
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Confirm Password"
            />
          </Form.Item>
          {/* Privacy Policy Acceptance */}

          <p className="ml-1 flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
              />
            </svg>
            By clicking Sign Up, I agree to the{" "}
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </p>

          <Form.Item>
            <button
              htmlType="submit"
              type="submit"
              className="mt-1 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm md:text-md lg:text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Sign Up
            </button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default SignUpForm;

// firebaseConfig.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'

// import { initializeApp } from 'firebase/app';
// import { getAuth, RecaptchaVerifier, PhoneAuthProvider, signInWithPhoneNumber } from 'firebase/auth';

// Your Firebase configuration details
// Firebase configuration from environment variables
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
// };



const firebaseConfig = {
  apiKey: "AIzaSyCexgRSK_DGcc8vfOOjmqzJ_nNw__mjz0E",
  authDomain: "swiftlead-5bf3e.firebaseapp.com",
  projectId: "swiftlead-5bf3e",
  storageBucket: "swiftlead-5bf3e.firebasestorage.app",
  messagingSenderId: "288835315945",
  appId: "1:288835315945:web:b629f5bca5784b2192a878"
};



// Initialize Firebase
firebase.initializeApp(firebaseConfig);



export default firebase;

import React from "react";
import { Form, Input, message } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { createClient } from "../../utils/supabase/client";

const supabase = createClient();

const SignInForm = ({ isSignUp }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const { email, password } = values;

    messageApi.loading("Please wait...", 0);

    // Check if user entered both email and password
    if (!email || !password) {
      messageApi.destroy();
      message.warning("Please enter an email and a password");
      return;
    }

    try {
      // Sign in logic
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        messageApi.destroy();
        message.error(error.message);
        return;
      }

      // Navigate to the dashboard on successful login
      messageApi.destroy();
      message.success("Logged in successfully!");
      navigate("/dashboard");
    } catch (err) {
      messageApi.destroy();
      message.error("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="flex justify-center">
      {contextHolder}
      <Form
        name="login"
        initialValues={{ remember: true }}
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        className="w-full"
      >
        {/* Email */}
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input your Email!" }]}
        >
          <Input size="large" prefix={<MailOutlined />} placeholder="Email" />
        </Form.Item>
        {/* Password */}
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined />}
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <button
            htmlType="submit"
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm md:text-md lg:text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isSignUp ? "Sign Up" : "Sign In"}
          </button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignInForm;

import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-[#191e62] to-indigo-600">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-white">404</h1>
        <p className="text-2xl md:text-3xl text-white font-light mb-8">
          Oops! The page you're looking for doesn’t exist.
        </p>
        <Link
          to="/"
          className="px-6 py-3 text-lg font-semibold text-indigo-600 bg-white rounded shadow hover:bg-indigo-50 focus:ring-4 focus:ring-indigo-300"
        >
          Go back to Homepage
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
